.form-enter {
  opacity: 0;
  transform: translateY(-200px);
}
.form-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 500ms;
}
.form-exit {
  opacity: 1;
}
.form-exit-active {
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 300ms, transform 500ms;
}
